"use client"

import { createContext, ReactNode, useContext } from "react"
import { UseTranslationResponse } from "react-i18next"

interface ContextValue {
  useI18n: (...ns: string[]) => UseTranslationResponse<string[], undefined>
}

const Context = createContext<ContextValue>({} as ContextValue)

export const ContextProvider = (props: {
  value: ContextValue
  children: ReactNode
}) => <Context.Provider {...props} />

export const useI18n = () => useContext(Context).useI18n()
