"use client"

import { SUPPORTED_LANGUAGES } from "@cyna/common/constants"
import { HOME_PATH } from "@cyna/common/routes"
import { LoaderLogo } from "@cyna/components/base/Loader"
import { Page } from "@cyna/components/base/Page"
import { Typo } from "@cyna/components/base/Typo"
import { ContextProvider } from "@cyna/components/providers/ContextProvider"
import { Button } from "@cyna/components/ui/button"
import PublicLayout from "@cyna/web/app/[lang]/(public)/layout"
import { useI18n } from "@cyna/web/hooks/useI18n"
import { usePathname } from "next/navigation"
import { ReactNode } from "react"

const urlLanguageRegex = new RegExp(`^/${SUPPORTED_LANGUAGES.join("|")}/`, "u")
const ContextLayout = (props: { children: ReactNode }) => (
  <ContextProvider {...props} value={{ useI18n }} />
)
const NotFoundPage = () => {
  const {
    t,
    i18n: { language },
  } = useI18n()
  const pathname = usePathname()
  const hasLanguage = urlLanguageRegex.test(pathname)

  return hasLanguage ? (
    <ContextLayout>
      <PublicLayout>
        <Page breadcrumbs={["public", "notFound"]}>
          <Typo>
            <p>{t("public:notFound.description")}</p>
          </Typo>
          <Button asChild className="mt-4 w-fit">
            <a href={`/${language}${HOME_PATH}`}>{t("public:notFound.cta")}</a>
          </Button>
        </Page>
      </PublicLayout>
    </ContextLayout>
  ) : (
    <LoaderLogo />
  )
}

export default NotFoundPage
