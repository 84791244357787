import { Typo } from "@cyna/components/base/Typo"
import { useI18n } from "@cyna/components/providers/ContextProvider"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@cyna/components/ui/breadcrumb"
import { Separator } from "@cyna/components/ui/separator"
import { SidebarTrigger } from "@cyna/components/ui/sidebar"
import { cn } from "@cyna/components/utils"

import { Fragment, ReactNode } from "react"

export const Page = ({
  children,
  className,
  breadcrumbs,
  lastCrumb,
  cta,
  hasNarrowContent,
}: {
  breadcrumbs: [string, string, ...string[]]
  lastCrumb?: string
  children: ReactNode
  className?: string
  cta?: ReactNode
  hasNarrowContent?: boolean
}) => {
  const [i18nSource, ...crumbs] = breadcrumbs
  const { t } = useI18n()

  return (
    <div className="w-full">
      <div className="bg-background sticky top-0 z-10 mb-2 shadow-sm">
        <div className="flex items-center gap-4 p-4">
          <SidebarTrigger />
          <Breadcrumb>
            <BreadcrumbList>
              {crumbs.map((crumb) => (
                <Fragment key={crumb}>
                  <BreadcrumbItem className="text-base">
                    {t(`${i18nSource}:breadcrumbs.${crumb}`)}
                  </BreadcrumbItem>
                  <BreadcrumbSeparator className="first:hidden last:hidden" />
                </Fragment>
              ))}
              {lastCrumb && (
                <BreadcrumbItem className="text-base font-medium">
                  {lastCrumb}
                </BreadcrumbItem>
              )}
            </BreadcrumbList>
          </Breadcrumb>
        </div>
        <Separator />
      </div>
      <div className="mx-auto max-w-7xl p-6 sm:min-w-96">
        <div className="flex justify-between">
          <Typo as="h1" className="mb-4">
            <h1>
              {lastCrumb ??
                t(`${i18nSource}:breadcrumbs.${crumbs.slice(-1)[0]}`)}
            </h1>
          </Typo>
          {cta}
        </div>
        <div
          className={cn(
            "mx-auto",
            hasNarrowContent && "mx-0 flex max-w-xl flex-col gap-4",
            className,
          )}
        >
          {children}
        </div>
      </div>
    </div>
  )
}
